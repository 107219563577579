import * as React from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Logo from '../../assets/images/favIcon.png';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import Dialog from '../UI/Controls/Dialog/Dialog';
import VideoDialog from '../UI/Controls/Video/VideoDialog';
import ReplySharpIcon from '@mui/icons-material/ReplySharp';

export default function ChatBoat({ onOpen, onClose, anchorEl, id, getCurrentTime, scrollToSection }) {
    const [openRequestDemo, setOpenRequestDemo] = React.useState(false);
    const [openVideo, setOpenVideo] = React.useState(false);
    const [text, setText] = React.useState('')

    const closeRequestHandler = () => {
        setOpenRequestDemo(false);
    }

    const closeVideoHandler = () => {
        setOpenVideo(false);
    }

    const openWhatsappHandler = () => {
        const whatsappUrl = `https://api.whatsapp.com/send?phone=7760204876&text=${encodeURIComponent(text)}`;
        window.open(whatsappUrl, '_blank');
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && text.trim() !== '') {
            openWhatsappHandler();
        }
    };

    return (
        <div>
            <Dialog open={openRequestDemo} onClose={closeRequestHandler} />
            <VideoDialog open={openVideo} onClose={closeVideoHandler} />
            <Popover
                id={id}
                open={onOpen}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                BackdropProps={{
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        backdropFilter: 'blur(10px)',
                    },
                }}
                PaperProps={{
                    style: { overflow: 'hidden' },
                }}
            >
                <Box
                    sx={{
                        width: 369,
                        height: 416,
                        backgroundColor: 'transparent',
                    }}
                >
                    <Grid container className='chat-box-container'>
                        <Grid item xs={8} style={{ display: 'flex' }}>
                            <div className='logo-container'>
                                <div>
                                    <img src={Logo} alt='ezybill' />
                                </div>
                                <div style={{ marginTop: '-30px' }}>
                                    <h5 style={{ color: '#fff', height: '0px' }}>ezybill | Support</h5>
                                    <p style={{ color: '#fff', fontSize: '10px', height: '0px' }}>Online</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} style={{ marginTop: '16px', textAlign: 'right' }}>
                            <CloseIcon style={{ color: '#fff', fontSize: '25px', cursor: 'pointer', marginRight: '12px' }} onClick={onClose} />
                        </Grid>
                        <Grid item xs={12}>
                            <div className='whtsapp-main-container'>
                                <div className='chat-middle-container'>

                                    <button className='outline-button-whtsapp'>
                                        <a style={{ textDecoration: 'none' }} rel='noreferrer' href='https://api.whatsapp.com/send/?phone=917760204876&text=Book Demo&type=phone_number&app_absent=0' target='_blank'> <ReplySharpIcon style={{ height: '15px' }} />Book a FREE Demo</a>
                                    </button>
                                    <button className='outline-button-whtsapp'>
                                        <a style={{ textDecoration: 'none' }} rel='noreferrer' href='https://api.whatsapp.com/send/?phone=917760204876&text=Get Mobile App&type=phone_number&app_absent=0' target='_blank'> <ReplySharpIcon style={{ height: '15px' }} />Get iOS/Andriod Mobile App Info</a>
                                    </button>
                                    {/* <button className='outline-button-whtsapp'>
                                        <a style={{ textDecoration: 'none' }} rel='noreferrer' href='https://api.whatsapp.com/send/?phone=917760204876&text=Get Mobile App&type=phone_number&app_absent=0' target='_blank'> <ReplySharpIcon style={{ height: '15px' }} />Watch Demo Video</a>
                                    </button> */}
                                    {/* <button className='outline-button-whtsapp'>
                                        <a style={{ textDecoration: 'none' }} rel='noreferrer' href='https://api.whatsapp.com/send/?phone=917760204876&text=Request a Call Back&type=phone_number&app_absent=0' target='_blank'> <ReplySharpIcon style={{ height: '15px' }} />Hi! Need more details. Please Call Back</a>
                                    </button> */}
                                    <button className='outline-button-whtsapp'>
                                        <a style={{ textDecoration: 'none' }} rel='noreferrer' href='https://api.whatsapp.com/send/?phone=917760204876&text=Get Platinum Details&type=phone_number&app_absent=0' target='_blank'> <ReplySharpIcon style={{ height: '15px' }} />Get Platinum Plan Details</a>
                                    </button>
                                    <button className='outline-button-whtsapp'>
                                        <a style={{ textDecoration: 'none' }} rel='noreferrer' href='https://api.whatsapp.com/send/?phone=917760204876&text=Request a Call Back&type=phone_number&app_absent=0' target='_blank'> <ReplySharpIcon style={{ height: '15px' }} />Request Call Back</a>
                                    </button>
                                    <br></br>
                                </div>
                                <div className="playground">
                                    <div className="container-send-msg">
                                        <div className="content">
                                            <input
                                                type="search"
                                                placeholder="Type your message"
                                                onKeyDown={handleKeyDown}
                                                onChange={(e) => setText(e.target.value)}
                                            />
                                            <SendIcon style={{ color: '#075E54' }} onClick={openWhatsappHandler} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </Popover>
        </div>
    );
}
